import React, { Component } from "react";
import _ from "lodash";
import styled from "styled-components";
import { component } from "../../component";
import { X, XInit, x } from "../../XObject";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  smallTitleCss,
  largeTitleCss,
  smallTitleClassName,
  largeTitleClassName,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { sendEmail } from "../../sendEmail";
import { Field } from "../Field";
import { DropdownSelect } from "../DropdownSelect";
import { languageOptions } from "../../data/languages";
import { saveToRequestedTitles } from "../../requestTitle";
import { streamingOptions } from "../../data/streamers";
import { ContactUsModal } from "../ContactUsModal";

@component
export class RequestAMovieSection extends Component<{ className?: string }> {
  state = XInit(
    class {
      errors = {};
      language = null;
      streamer = null;
      showModal = false;
    }
  );

  static styles = styled.div`
    ${desktopBreakpoint} {
      padding: 128px 0;
    }
    ${mobileBreakpoint} {
      padding: 48px 0;
    }
    ${innerContCss}

    .innerCont {
      ${desktopBreakpoint} {
        display: flex;
      }
    }

    .left {
      ${desktopBreakpoint} {
        width: 480px;
        margin-right: 88px;
      }
      ${mobileBreakpoint} {
        margin-bottom: 24px;
      }
      flex: 0 0 auto;

      .${smallTitleClassName} {
        ${smallTitleCss}
      }

      .${largeTitleClassName} {
        ${largeTitleCss}
      }
    }

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px 24px;

      ${Button} {
        max-width: fit-content;
        box-shadow: 0px 4px 8px rgba(17, 0, 82, 0.2);
      }

      .name {
        display: flex;

        > * {
          &:not(:last-child) {
            margin-right: 24px;
          }
          width: 100%;
        }
      }
    }
  `;

  render() {
    return (
      <>
        <div className="innerCont" id="contact-us">
          <div className="left">
            <h2 className={largeTitleClassName}>Request a movie</h2>
            <p>
              Don't see a show or movie that you want ASL interpretations for?
              Request an interpretation or submit other feedback here.
            </p>
            <Button
              button
              theme={ButtonTheme.secondary2}
              text="Want to Chat? Contact Us"
              onClick={() => this.setState({ showModal: true })}
            />
          </div>

          <form
            aria-label="Contact information"
            className="right"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              this.state.errors = X({});

              if (!(e.target as any).firstName.value) {
                this.state.errors["firstName"] = true;
              }
              if (!(e.target as any).lastName.value) {
                this.state.errors["lastName"] = true;
              }
              if (!(e.target as any).email.value) {
                this.state.errors["email"] = true;
              }
              if (!(e.target as any).title.value) {
                this.state.errors["title"] = true;
              }
              if (!(e.target as any).streamer.value) {
                this.state.errors["streamer"] = true;
              }

              if (
                (e.target as any).language.value === "Other" &&
                !(e.target as any).other.value
              ) {
                this.state.errors["other"] = true;
              }

              if (!_.isEmpty(x(this.state.errors))) {
                // go to the first error and focus on it
                const firstError = Object.keys(this.state.errors)[0];
                const firstErrorElement = document.getElementById(firstError);
                firstErrorElement?.focus();
                return;
              }

              sendEmail({
                firstName: (e.target as any).firstName.value,
                lastName: (e.target as any).lastName.value,
                emailAddress: (e.target as any).email.value,
                language: this.state.language,
                other: (e.target as any).other?.value,
                title: (e.target as any).title.value,
                streamer: (e.target as any).streamer.value,
              });

              if ((e.target as any).title?.value) {
                saveToRequestedTitles({
                  title: (e.target as any).title.value,
                  language:
                    this.state?.language ?? (e.target as any).other?.value,
                  email: (e.target as any).email.value,
                  streamer: (e.target as any).streamer.value,
                });
              }

              alert(
                "Thank you for your request! We will get back to you soon."
              );
              (e.target as any).reset();
              gtag("event", "Movie Request sent");
            }}
          >
            <div className="name">
              <Field
                label="First Name"
                postfix="(required)"
                labelFor="firstName"
                error={this.state.errors["firstName"]}
                errorMessage="Please enter at least 1 character."
                hintId="first-name-hint"
              >
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  required
                  className={this.state.errors["firstName"] && "error"}
                  aria-invalid={this.state.errors["firstName"]}
                  aria-describedby="first-name-hint"
                />
              </Field>

              <Field
                label="Last Name"
                postfix="(required)"
                labelFor="lastName"
                error={this.state.errors["lastName"]}
                errorMessage="Please enter at least 1 character."
                hintId="last-name-hint"
              >
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  required
                  className={this.state.errors["lastName"] && "error"}
                  aria-invalid={this.state.errors["lastName"]}
                  aria-describedby="last-name-hint"
                />
              </Field>
            </div>

            <Field
              label="Email"
              postfix="(required)"
              labelFor="email"
              error={this.state.errors["email"]}
              errorMessage="Please enter a valid email address."
              hintId="email-hint"
            >
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                required
                className={this.state.errors["email"] && "error"}
                aria-invalid={this.state.errors["email"]}
                aria-describedby="email-hint"
              />
            </Field>

            <Field
              label="Language"
              postfix="(required)"
              labelFor="language"
              error={this.state.errors["language"]}
              errorMessage="Please select a language."
              hintId="language-hint"
            >
              <DropdownSelect
                id="language"
                options={languageOptions}
                value={this.state.language}
                onChange={(value) => {
                  this.state.language = value;
                }}
                required
                ariaDescribedby="language-hint"
                ariaInvalid={this.state.errors["language"]}
              />
            </Field>
            {this.state.language === "Other" ? (
              <Field
                label="Other Language"
                postfix="(required)"
                labelFor="other"
                error={this.state.errors["other"]}
                errorMessage="Please enter a language."
                hintId="other-language-hint"
              >
                <input
                  type="text"
                  name="other"
                  required
                  aria-describedby="other-language-hint"
                  aria-invalid={this.state.errors["other"]}
                  className={this.state.errors["other"] && "error"}
                />
              </Field>
            ) : null}

            <Field
              label="Request movie title"
              postfix="(required)"
              labelFor="title"
              error={this.state.errors["title"]}
              errorMessage="Please select a title."
              hintId="title-hint"
            >
              <input
                type="text"
                name="title"
                id="title"
                required
                aria-describedby="title-hint"
                aria-invalid={this.state.errors["title"]}
                className={this.state.errors["other"] && "error"}
              />
            </Field>

            <Field
              label="Streaming Service"
              postfix="(required)"
              labelFor="streamer"
              error={this.state.errors["streamer"]}
              errorMessage="Please select a streaming service."
              hintId="streamer-hint"
            >
              <DropdownSelect
                id="streamer"
                options={streamingOptions}
                value={this.state.streamer}
                onChange={(value) => {
                  this.state.streamer = value;
                }}
                required
                ariaDescribedby="streamer-hint"
                ariaInvalid={this.state.errors["streamer"]}
              />
            </Field>

            <Button button theme={ButtonTheme.primary} text="Send" />
          </form>
        </div>
        {this.state.showModal && (
          <ContactUsModal onClose={() => this.setState({ showModal: false })} />
        )}
      </>
    );
  }
}
